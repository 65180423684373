import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  faBolt,
  faCloudRain,
  faSnowflake,
  faTemperatureHigh,
  faTemperatureLow,
  faTh,
  faWind,
} from '@fortawesome/free-solid-svg-icons';
import { Feature } from 'ol';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MapHandlerService } from '../utility/map-handler.service';

interface PhenomeneItem {
  name: string;
  icon: string;
  description: string;
  img?: string;
  faIcon?: any;
  color?: string;
}

const VIGILANCE_GENERALE_PHENOMENES: PhenomeneItem[] = [
  {
    name: 'Tous',
    icon: 'fas fa-th',
    faIcon: faTh,
    img: 'tous.png',
    description: 'Tous les phénomènes',
  },
  {
    name: 'canicule',
    icon: 'fas fa-temperature-high',
    faIcon: faTemperatureHigh,
    img: 'canicule.png',
    description: 'Canicule',
  },
  {
    name: 'vent-violent',
    icon: 'fas fa-wind',
    faIcon: faWind,
    img: 'vent-violent.png',
    description: 'Vent violent',
  },
  {
    name: 'pluie',
    icon: 'fas fa-cloud-rain',
    faIcon: faCloudRain,
    img: 'pluie.png',
    description: 'Pluie',
  },
  {
    name: 'orage',
    icon: 'fas fa-bolt',
    faIcon: faBolt,
    img: 'orage.png',
    description: 'Orage',
  },
  {
    name: 'neige&verglas',
    icon: 'fas fa-snowflake',
    faIcon: faSnowflake,
    img: 'neige&verglas.png',
    description: 'Neige et verglas',
  },
  {
    name: 'vent-de-sable',
    icon: 'icon-sandstorm',
    img: 'vent-de-sable.png',
    description: 'Vent de sable',
  },
  {
    name: 'grand-froid',
    icon: 'fas fa-temperature-low',
    img: 'grand-froid.png',
    faIcon: faTemperatureLow,
    description: 'Grand froid',
  },
];

const VIGILANCE_MARINE_PHENOMENES: PhenomeneItem[] = [
  {
    name: 'Tous',
    icon: 'fas fa-th',
    faIcon: faTh,
    img: 'tous.png',
    description: 'Tous les phénomènes',
  },
  {
    name: 'vent-violent',
    icon: 'fas fa-wind',
    faIcon: faWind,
    img: 'vent-violent.png',
    description: 'Vent violent',
  },
  {
    name: 'vague-dangereuse',
    icon: 'icon-wave',
    img: 'vague-dangereuse.png',
    description: 'Vague dangereuse',
  },
];

@Injectable({
  providedIn: 'root',
})
export class VigilanceDataService {
  private VGMapSub: Subject<any> = new ReplaySubject(1);
  vigilanceGenerale$ = this.VGMapSub.asObservable();

  private VMMapSub: Subject<any> = new ReplaySubject(1);
  vigilanceMarine$ = this.VMMapSub.asObservable();

  private _miniMapSub: Subject<any> = new Subject();
  vigilanceMiniMap$ = this._miniMapSub.asObservable();

  private _vigiTableSub: Subject<any> = new Subject();
  vigitable$ = this._vigiTableSub.asObservable();
  vigiTableHours: any;

  private _vigiTableMSub: Subject<any> = new Subject();
  vigitableM$ = this._vigiTableMSub.asObservable();
  vigiTableHoursM: any;


  private zonesSub: BehaviorSubject<any> = new BehaviorSubject([]);
  zones$ = this.zonesSub.asObservable();

  private _VGFlashInfosSub: Subject<any> = new Subject();
  VGFlashInfos$ = this._VGFlashInfosSub.asObservable();

  // VG -> Vigilance Generale
  private VGMapDataCache: any;
  private VGTableDataCache: any = {};

  private VMMapDataCache: any = {};
  private VMTableDataCache: any = {};

  constructor(
    private http: HttpClient,
    private mapHandlerService: MapHandlerService
  ) {}

  getVGPhenomenes() {
    return VIGILANCE_GENERALE_PHENOMENES;
  }

  getVMPhenomenes() {
    return VIGILANCE_MARINE_PHENOMENES;
  }

  // emitVigilanceGenerale(day: number,forMiniMap: boolean = false) {
  //   console.log("monday1", day);
  //   if (this.VGMapDataCache) {
  //     const pheno = this.setPhenomenesVigilanceColors(
  //       [...VIGILANCE_GENERALE_PHENOMENES],
  //       this.VGMapDataCache.data
  //     );
  //     if (forMiniMap) {
  //       this._miniMapSub.next({
  //         mapData: this.VGMapDataCache,
  //         mapMode: 'vigilanceGenerale',
  //         phenomenes: pheno,
  //       });
  //     } else {
  //       this.VGMapSub.next({
  //         mapData: this.VGMapDataCache,
  //         mapMode: 'vigilanceGenerale',
  //         phenomenes: pheno,
  //       });
  //     }
  //   } else {
  //     console.log("monday2", day);
      
  //     this.mapHandlerService.initMapLoading();
  //     this.findVigilanceGenerale(day).subscribe(
  //       (vgData: any) => {
  //         const pheno = this.setPhenomenesVigilanceColors(
  //           [...VIGILANCE_GENERALE_PHENOMENES],
  //           vgData.data
  //         );
  //         this.VGMapDataCache = vgData;
  //         if (forMiniMap) {
  //           this._miniMapSub.next({
  //             mapData: this.VGMapDataCache,
  //             mapMode: 'vigilanceGenerale',
  //             phenomenes: pheno,
  //           });
  //         } else {
  //           this.VGMapSub.next({
  //             mapData: this.VGMapDataCache,
  //             mapMode: 'vigilanceGenerale',
  //             phenomenes: pheno,
  //           });
  //         }
  //         this.mapHandlerService.stopMapLoading();
  //       },
  //       (error) => {
  //         // TODO: Handle error
  //       }
  //     );
  //   }
  // }

  emitVigilanceGenerale(day: number, forMiniMap: boolean = false) {
    console.log("monday1", day);
    
    // Force VGMapDataCache to be undefined/null for testing
    this.VGMapDataCache = null; 
  
    if (this.VGMapDataCache) {
      const pheno = this.setPhenomenesVigilanceColors(
        [...VIGILANCE_GENERALE_PHENOMENES],
        this.VGMapDataCache.data
      );
      if (forMiniMap) {
        this._miniMapSub.next({
          mapData: this.VGMapDataCache,
          mapMode: 'vigilanceGenerale',
          phenomenes: pheno,
        });
      } else {
        this.VGMapSub.next({
          mapData: this.VGMapDataCache,
          mapMode: 'vigilanceGenerale',
          phenomenes: pheno,
        });
      }
    } else {
      console.log("monday2", day);
      
      this.mapHandlerService.initMapLoading();
      this.findVigilanceGenerale(day).subscribe(
        (vgData: any) => {
          const pheno = this.setPhenomenesVigilanceColors(
            [...VIGILANCE_GENERALE_PHENOMENES],
            vgData.data
          );
          this.VGMapDataCache = vgData;
          if (forMiniMap) {
            this._miniMapSub.next({
              mapData: this.VGMapDataCache,
              mapMode: 'vigilanceGenerale',
              phenomenes: pheno,
            });
          } else {
            this.VGMapSub.next({
              mapData: this.VGMapDataCache,
              mapMode: 'vigilanceGenerale',
              phenomenes: pheno,
            });
          }
          this.mapHandlerService.stopMapLoading();
        },
        (error) => {
          console.log("Error in findVigilanceGenerale:", error);
          // TODO: Handle error
        }
      );
    }
  }
  

  emitVigilanceMarine(day: number) {
    if (this.VMMapDataCache[day]) {
      const pheno = this.setPhenomenesVigilanceColors(
        [...VIGILANCE_MARINE_PHENOMENES],
        this.VMMapDataCache[day].data
      );
      this.VMMapSub.next({
        mapData: this.VMMapDataCache[day],
        mapMode: 'vigilanceMarine',
        phenomenes: pheno,
      });
    } else {
      this.mapHandlerService.initMapLoading();
      this.findVigilanceMarineByDay(day).subscribe(
        (vmData: any) => {
          const pheno = this.setPhenomenesVigilanceColors(
            [...VIGILANCE_MARINE_PHENOMENES],
            vmData.data
          );
          this.VMMapDataCache[day] = vmData;
          this.VMMapSub.next({
            mapData: vmData,
            mapMode: 'vigilanceMarine',
            phenomenes: pheno,
          });
          this.mapHandlerService.stopMapLoading();
        },
        (error) => {
          // TODO: Handle error
        }
      );
    }
  }

  emitZones(zones: any) {
    this.zonesSub.next(zones);
  }

  // emitVGTableByCodeWilaya(feature: Feature, phenomene: string, day: number) {
  //   phenomene = phenomene.toUpperCase();
  //   const code = feature.get('CODE');
  //   if (!this.VGTableDataCache[code]) {
  //     this.findVGByCodeWilaya(code, day).subscribe((data) => {
  //       if (!this.vigiTableHours) {
  //         // TODO: write to function
  //         this.vigiTableHours = data['CANICULE'].map((ech) => ech.date_debut);
  //       }
  //       this.VGTableDataCache[code] = data;
  //       this._vigiTableSub.next(
  //         phenomene === 'TOUS'
  //           ? {
  //               hours: this.vigiTableHours,
  //               data: this.VGTableDataCache[code],
  //               name: feature.get('WILAYA'),
  //               prev_alerte: feature.get('VGProps').prev_alerte,
  //             }
  //           : {
  //               hours: this.vigiTableHours,
  //               data: {
  //                 [`${phenomene}`]: this.VGTableDataCache[code][phenomene],
  //               },
  //               name: feature.get('WILAYA'),
  //               prev_alerte: feature.get('VGProps').prev_alerte,
  //             }
  //       );
  //     }),
  //       (error) => {
  //         // TODO: Handle error
  //       };
  //   } else {
  //     this._vigiTableSub.next(
  //       phenomene === 'TOUS'
  //         ? {
  //             hours: this.vigiTableHours,
  //             data: this.VGTableDataCache[code],
  //             name: feature.get('WILAYA'),
  //             prev_alerte: feature.get('VGProps').prev_alerte,
  //           }
  //         : {
  //             hours: this.vigiTableHours,
  //             data: {
  //               [`${phenomene}`]: this.VGTableDataCache[code][phenomene],
  //             },
  //             name: feature.get('WILAYA'),
  //             prev_alerte: feature.get('VGProps').prev_alerte,
  //           }
  //     );
  //   }
  // }

  emitVGTableByCodeWilaya(feature: Feature, phenomene: string, day: number) {
    phenomene = phenomene.toUpperCase();
    const code = feature.get('CODE');
  
    this.findVGByCodeWilaya(code, day).subscribe(
      (data) => {
        console.log("dayyyyyyyyyyyyy", day);
  
        // Update vigiTableHours every time
        this.vigiTableHours = data['table_hours'].map((ech) => ech);
        console.log("testttt", this.vigiTableHours);
  
        // Move table_hours inside CANICULE
        if (data['CANICULE']) {
          data['CANICULE'].forEach((ech) => {
            ech['table_hours'] = data['table_hours'];
          });
        }
  
        // Remove the table_hours key from the response
        delete data['table_hours'];
  
        this.VGTableDataCache[code] = data;
        this._vigiTableSub.next(
          phenomene === 'TOUS'
            ? {
                hours: this.vigiTableHours,
                data: this.VGTableDataCache[code],
                name: feature.get('WILAYA'),
                prev_alerte: feature.get('VGProps').prev_alerte,
              }
            : {
                hours: this.vigiTableHours,
                data: {
                  [`${phenomene}`]: this.VGTableDataCache[code][phenomene],
                },
                name: feature.get('WILAYA'),
                prev_alerte: feature.get('VGProps').prev_alerte,
              }
        );
      },
      (error) => {
        // TODO: Handle error
      }
    );
  }

  emitVMTableByZone(feature: Feature, phenomene: string, day: number) {
    phenomene = phenomene.toUpperCase();
    const zone = feature.get('Zone');
    if (!this.VMTableDataCache[day]) this.VMTableDataCache[day] = {};
    if (!this.VMTableDataCache[day][zone]) {
      // NO CACHE
      this.findVMByCodeWilaya(zone, day).subscribe((data) => {
        if (!this.vigiTableHoursM) {
          // TODO: write to function
          this.vigiTableHoursM = data['VENT-VIOLENT'].map(
            (ech) => ech.dateDebut
          );
        }
        this.VMTableDataCache[day][zone] = data;
        this._vigiTableMSub.next(
          phenomene === 'TOUS'
            ? {
                hours: this.vigiTableHoursM,
                data: this.VMTableDataCache[day][zone],
                name: feature.get('Zone'),
              }
            : {
                hours: this.vigiTableHoursM,
                data: {
                  [`${phenomene}`]: this.VMTableDataCache[day][zone][phenomene],
                },
                name: feature.get('Zone'),
              }
        );
      }),
        (error) => {
          // TODO: Handle error
        };
    } else {
      this._vigiTableMSub.next(
        phenomene === 'TOUS'
          ? {
              hours: this.vigiTableHoursM,
              data: this.VMTableDataCache[day][zone],
              name: feature.get('Zone'),
            }
          : {
              hours: this.vigiTableHoursM,
              data: {
                [`${phenomene}`]: this.VMTableDataCache[day][zone][phenomene],
              },
              name: feature.get('Zone'),
            }
      );
    }
  }

  findVigilanceMarineByDay(day: number) {
    return this.http.get(`${environment.API_VIGILANCE}/api/vigmarine/${day}/`);
  }

  // findVigilanceGenerale() {
  //   return this.http.get(`${environment.API_VIGILANCE}/api/sitewebmaps/`);
  // }

  findVigilanceGenerale(day: number) {
    return this.http.get(`https://ametvigilance.meteo.dz/api/sitewebmaps/${day}`);
    }

  // findVGByCodeWilaya(code: number, day: number) {
  //   return this.http.get(
  //     `${environment.API_VIGILANCE}/api/sitewebtable/${code}/TOUS/${day}`
  //   );
  // }
  
  findVGByCodeWilaya(code: number, day: number) {
    return this.http.get(
      `https://ametvigilance.meteo.dz/api/sitewebtable/${code}/TOUS/${day}`
    );
  }

  // findVGByCodeWilaya(code: number, day: number) {
  //   return this.http.get(
  //     `${environment.API_VIGILANCE}/api/sitewebtable/${code}/TOUS/`
  //   );
  // }

  findVMByCodeWilaya(zone: string, day: number) {
    return this.http.get(
      `${environment.API_VIGILANCE}/api/sitewebtablemarine/${zone}/TOUS/${day}`
    );
  }

  findFlashInfo() {
    return this.http.get(`${environment.API_VIGILANCE}/api/vigilanceinfos/`);
  }

  setPhenomenesVigilanceColors(
    phenomeneArray: PhenomeneItem[],
    phenomeneData: any
  ) {
    phenomeneArray.forEach((ph: PhenomeneItem) => {
      const data: any[] = phenomeneData[ph.name.toUpperCase()];
      if (data?.length > 0) {
        if (data.some((d) => d.gravite === 'ROUGE')) ph.color = 'rouge';
        else if (data.some((d) => d.gravite === 'ORANGE')) ph.color = 'orange';
        else if (data.some((d) => d.gravite === 'JAUNE')) ph.color = 'jaune';
        else ph.color = 'vert';
      }
    });
    // TODO: Handle error
    return phenomeneArray;
  }

  /*getVigilanceColorByGravite(phenomeneArray: PhenomeneItem[], phenomeneData: any) {
    const data: any[] = phenomeneData[ph.name.toUpperCase()];
    if (data?.length > 0) {
      if (data.some(d => d.gravite === 'ROUGE'))
        return 'rouge';
      else if (data.some(d => d.gravite === 'ORANGE'))
        return 'orange'
      else if (data.some(d => d.gravite === 'JAUNE'))
        return 'jaune'
      else
        return 'vert';
    }
  }*/
}
