<ng-container *ngIf="langConfig$ | async as langConfig">
  <ng-container *ngIf="article['titre_' + langConfig.language]; else noDataTpl">
    <div class="article-header" [style.direction]="langConfig.direction">
      <div class="article-info">
        <!-- <div class="article-title">
                    {{article["titre_"+langConfig.language]}}
                </div> -->

        <div
          class="article-title"
          [style.direction]="langConfig.direction"
          [innerHTML]="safeHTML(article['titre_' + langConfig.language])"
        ></div>

        <!-- <div class="article-description">
                    {{article["description_"+langConfig.language]}}
                </div> -->

        <div
          class="article-description"
          [style.direction]="langConfig.direction"
          [innerHTML]="safeHTML(article['description_' + langConfig.language])"
        ></div>

        <div class="article-date">
          {{ article.created_at | date : "dd/MM/YYYY" }}
        </div>
      </div>

      <div class="img-hover-zoom">
        <img
          [src]="API_URL + article.imgsrc"
          alt="Article image"
          class="article-image"
        />
      </div>
      <div>
      </div>
      <div *ngIf="article.titre_fr === 'Consultations'" class="button-container">
        <button class="blink-button" (click)="openDialog()">Avis aux fournisseurs</button>
      </div>

        <!-- Template pour le dialogue -->
        <ng-template #supplierDialog let-data>
          <h2 class="heading-1 mat-dialog-title">
            AVIS AUX FOURNISSEURS ET PRESTATAIRES DE SERVICES
            <i class="fas fa-times-circle modal-close-btn" (click)="closeDialog()"></i>
          </h2>
          <mat-dialog-content style="position: relative;">
            <p style="line-height: 1.6; margin-bottom: 1em;">
          
              Dans le cadre de la mise à jour continue de notre fichier fournisseur, l’ONM invite les prestataires intéressés par nos consultations à soumettre leurs offres de services. Veuillez suivre les étapes ci-dessous pour participer.
            </p>
        
            <h3 style="font-weight: bold; margin-top: 20px;">Étapes à Suivre :</h3>
            <ol style="line-height: 1.6; margin-left: 30px;">
              <li><strong>Téléchargez</strong> et complétez le formulaire disponible via le lien ci-dessous.</li>
              <li>
                <strong>Joignez</strong> au formulaire les documents suivants :
                <ul style="list-style-type: disc; margin-left: 40px;">
                  <li>Une présentation détaillée de votre activité,</li>
                  <li>Vos références professionnelles,</li>
                  <li>Vos certifications ou tout autre document pertinent.</li>
                </ul>
              </li>
              <li><strong>Envoyez</strong> l’ensemble des documents par e-mail à l’adresse indiquée.</li>
            </ol>
        
            <p style="line-height: 1.6; margin-top: 20px;">
              <strong>Télécharger le formulaire :</strong>
              <a href="/assets/files/FICHE_D_INFORMATION_FOURNISSEUR.docx" download="FICHE_D_INFORMATION_FOURNISSEUR.docx" style="color: #007BFF; text-decoration: underline;">
                Cliquez ici
              </a>
            </p>
        
            <p style="line-height: 1.6;">
              <strong>Adresse e-mail pour l’envoi : </strong>
              <a href="mailto:fournisseurs@meteo.dz" style="color: #007BFF; text-decoration: underline;">fournisseurs@meteo.dz</a>
            </p>
        
            <p style="line-height: 1.6; margin-top: 20px;">
              Pour toute question supplémentaire, n’hésitez pas à nous contacter à l’adresse e-mail ci-dessus.
            </p>
          </mat-dialog-content>
        </ng-template>
    </div>
    <div
      class="article-content cs-article-content-body"
      [style.direction]="langConfig.direction"
      [innerHTML]="safeHTML(article['contenu_' + langConfig.language])"
    ></div>

    <!--<pdf-viewer [external-link-target]="'blank'" [src]="'/assets/files/a_propos_onm.pdf'" [autoresize]="true"
            [original-size]='false' [render-text]="true" style="display: block;">
        </pdf-viewer>-->
  </ng-container>

  <ng-template #noDataTpl>
    <div class="grid place-items-center py-md">
      <div class="text-md text-white-3">
        {{ "article.non_disponible" | translate }}
      </div>
    </div>
  </ng-template>
</ng-container>
