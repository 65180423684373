import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BmsDataService {

  private _bmsSub: BehaviorSubject<any> = new BehaviorSubject({ data: null, nb: 0 });
  bms$ = this._bmsSub.asObservable();

  constructor(private http: HttpClient) {
    this.getAllBms();
  }

  getAllBms() {
    this.findAll().subscribe(
      (data: any) => {
        console.log('log', data);
        
        this._bmsSub.next({ data: data, nb: data.length });
      },
      (error) => {
        // TODO: Handle error
      }
    )
  }

  findAll() {
    return this.http.get(`${environment.API_VIGILANCE}/api/bmsvalide/`);
  }
}
