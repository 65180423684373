// export const environment = {
//   production: false,
//   API_URL: 'https://sitewebtest.meteo.dz',
//   API_VIGILANCE: 'https://ametvigilance.meteo.dz',
// };

export const environment = {
  production: false,
  API_URL: 'https://www.meteo.dz',
  API_VIGILANCE: 'https://ametvigilance.meteo.dz',
};
