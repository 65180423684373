import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TraductionService } from 'src/app/core/services/utility/traduction.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef  } from '@angular/material/dialog';
import {

  TemplateRef,
  ViewChild,
} from '@angular/core';


@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent implements OnInit {

  @Input() article: any;
  @ViewChild('supplierDialog') supplierDialog!: TemplateRef<any>;

  dialogRef: MatDialogRef<any> | null = null;
  // dialogRef: MatDialogRef<any>;

  langConfig$ = this.traductionService.langConfig$;

  API_URL = environment.API_URL;

  constructor(private traductionService: TraductionService,
    private sanitizer: DomSanitizer, private dialog: MatDialog) { }


    openDialog(): void {
      this.dialogRef = this.dialog.open(this.supplierDialog, {
        width: '600px',
        data: { closeDialog: () => this.dialogRef.close() },
        panelClass: 'cs-dialog',
      });
    
      this.dialogRef.afterOpened().subscribe(() => {
        setTimeout(() => {
          const dialogContainer = document.querySelector('.mat-dialog-container') as HTMLElement;
          if (dialogContainer) {
            dialogContainer.scrollTop = 0; // Force scroll to top
          }
        }, 0); // Exécution après le rendu
      });
    }
    
    closeDialog(): void {
      
      if (this.dialogRef) 
      {
        this.dialogRef?.close();
      } else {
        console.error('dialogRef is null');
      }
    }
  
    // supplierDialog: any;

  ngOnInit(): void {

    console.log("API_URL", this.API_URL);
    console.log("article", this.article);
    
    
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }

}
